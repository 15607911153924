import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {callApiUrl} from '../utils/functions'
import { addToCart, showNotification, hideNotification, setPickup, saveUser } from '../utils/store'
import Modal from '../components/Modal'
import { useLoadScript } from "@react-google-maps/api"
import PlacesAutocomplete from '../components/PlacesAutocomplete'

function LocationSkeleton(props) {
    return (
        <div className="box location-card loading">
        </div>
    );
}

function LoadingLocations(props) {
  return (
    <div>
        <div className="columns">
            <div className="column"><LocationSkeleton></LocationSkeleton></div>
            <div className="column"><LocationSkeleton></LocationSkeleton></div>
        </div>
        <div className="columns">
            <div className="column"><LocationSkeleton></LocationSkeleton></div>
            <div className="column"><LocationSkeleton></LocationSkeleton></div>
        </div>
        <div className="columns">
            <div className="column"><LocationSkeleton></LocationSkeleton></div>
            <div className="column"><LocationSkeleton></LocationSkeleton></div>
        </div>
    </div>
  );
}

function Location() {

    const dispatch = useDispatch();

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
        libraries: ["places"],
    });

    const location = useSelector(state => state.location);
    const schedule = useSelector(state => state.schedule);
    const is_pickup = useSelector(state => state.is_pickup);
    const user = useSelector(state => state.user);
    
    const isLoadingLocation = useSelector(state => state.isLoadingLocation);
    const [isLoadingProducts, setIsLoadingProducts] = React.useState(true);
    const [loadMoreEnabled, setLoadMoreEnabled] = React.useState(true);
    const [isFetchingMoreProducts, setIsFetchingMoreProducts] = React.useState(false);
    const [products, setProducts] = React.useState({});
    const [offset, setOffset] = React.useState(0);
    const [selectedCategory, setSelectedCategory] = React.useState(false);
    const [showMobileFilters, setShowMobileFilters] = React.useState(false);

    // Address
    const [showAdressModal, setShowAdressModal] = React.useState(false);
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [postalCode, setPostalCode] = React.useState('');
    const [latitude, setLatitude] = React.useState('');
    const [longitude, setLongitude] = React.useState('');
    const [appartment, setAppartment] = React.useState('');
    const [persistUser, setPersistUser] = React.useState(true);

    // Product Modal
    const [focusedProduct, setFocusedProduct] = React.useState({
      name: '',
      images: '',
      description: '',
      price: '',
      available: true
    });
    const [productModalVisible, setProductModalVisible] = React.useState(false);
    const [infoModalVisible, setInfoModalVisible] = React.useState(false);
    const [optionsEnabled, setOptionsEnabled] = React.useState(true);
    const [isModalFetching, setIsModalFetching] = React.useState(true);
    const [options, setOptions] = React.useState([]);
    const [selectedVariant, setSelectedVariant] = React.useState(null);
    const [weightOptions, setWeightOptions] = React.useState([]);
    const [colorsOptions, setColorsOptions] = React.useState([]);
    const [stylesOptions, setStylesOptions] = React.useState([]);
    const [price, setPrice] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [productPrice, setProductPrice] = React.useState(0);
    const [focusedProductComment, setFocusedProductComment] = React.useState('');
    const [focusedProductQuantity, setFocusedProductQuantity] = React.useState(1);
    const [weightSelected, setWeightSelected] = React.useState(0);
    const [styleSelected, setStyleSelected] = React.useState(0);
    const [colorSelected, setColorSelected] = React.useState(0);
    const [variationChanging, setVariationChanging] = React.useState(false);

    const onPlaceSelected = (destination, latLng, zipCode) => {
        const destinationArray = destination.split(",");
        setAddress(destinationArray[0]);
        setCity(destinationArray[1]);
        setPostalCode(zipCode);
        setLatitude(latLng.lat);
        setLongitude(latLng.lng);
    }

    const handlePersistChange = (e) => {
        const target = e.target;
        const value = target.checked;
        setPersistUser(value);
    }

    const saveAddress = () => {

        let user = {
            shipping_address: {
                address: address,
                appartment: appartment,
                city: city,
                state: 'QC',
                country: 'Canada',
                postal_code: postalCode,
                latitude: latitude,
                longitude: longitude
            }
        }

        dispatch(saveUser({user: user, persist: persistUser}));
        setShowAdressModal(false);
    }

    function Product(props) {
        const product = props.product;
        
        const product_img = process.env.REACT_APP_ASSETS_URL + product.images;
        return (
            <div className="column is-full-mobile is-full-tablet is-half-desktop">
                <a className="card product-card" onClick={() => openProductModal(product)}>
                    <div className={`card-content ${product.images ? "has-img" : ""}`}>
                        <b className="is-size-6">
                            <span className="product-title">{product.name}</span>
                            <small style={{color: 'gray'}} className="product-description">
                                {product.description}
                            </small>
                        </b>
                        <b className="product-price">{product.price}$</b>
                    </div>
                    {
                        product.images ? (
                        <div className="card-product-img">
                            <img src={product_img} alt={product.name} />
                        </div>
                        ) : null
                    }
                </a>
            </div>
        );
    }

    function ProductCategory(props) {
        const category = props.category;
        const products = props.products;

        function ProductList() {
            
            const productsItems = products.map((product, index) =>
                <Product key={index} product={product} />
            );
            return (
            <>{productsItems}</>
            );
        }
        
        return (
            <div>
                <h2 className="is-size-5"><strong>{category}</strong></h2>
                <br/>
                <div className="columns is-multiline is-mobile" style={{marginBottom: '1em'}}>
                    <ProductList />
                </div>
            </div>
        );
    }

    function ProductCategoryList(props) {
        const productsItems = Object.keys(props.products).map(key => 
            <ProductCategory key={key} category={props.products[key].category_name} products={props.products[key].products} />
        );
        
        return (
            <>
            {
                Object.keys(props.products).length > 0 ? (
                    <div id="products-list">
                        {productsItems}
                    </div>
                ) : (
                <div>
                    <p>
                    Aucuns produits trouvés selon les critères
                    </p>
                    <button className="button is-primary">Réinitialiser</button>
                </div>
                )
            }
            </>
        );
    }

    const openProductModal = (product) => {
        setFocusedProduct(product);
        setIsModalFetching(true);
        setProductModalVisible(!productModalVisible);
    
        
        let apiUrl = process.env.REACT_APP_API_URL + '/product-api/get-variants-options/' + product.id_product;
        let body = {
            'single_location' : true
        }

        callApiUrl('POST', apiUrl, body)
        .then(resJson => {
            setPrice(parseFloat(resJson.price));
            if (!resJson.available){
                setOptionsEnabled(false);
                setIsModalFetching(false);
            }else{
                setOptionsEnabled(true);
                
                let options = [];
                let weight = [];
                let colors = [];
                let styles = [];

                if (resJson.has_extras == 1){
                    options = resJson.extras;
                }
                if (resJson.has_weight){
                    weight = resJson.weight;
                }
                if (resJson.has_colors){
                    colors = resJson.colors;
                }
                if (resJson.has_styles){
                    styles = resJson.styles;
                }

                if (resJson.no_variant){
                    setSelectedVariant(null);
                }else{
                    setSelectedVariant(resJson.id_product_variant);
                }

                setOptions(options);
                setWeightOptions(weight);
                setColorsOptions(colors);
                setStylesOptions(styles);
                setProductPrice(parseFloat(resJson.price));
                setIsModalFetching(false);
            }
        })
        .catch(error => {
            setOptionsEnabled(false);
        });
    }

    const closeModal = () => {
        setProductModalVisible(false);
    }

    const updateProducts = (old_products, new_products, new_products_offset) => {

        for (let i = 0; i < new_products_offset; i++){
            let product = new_products[i];
            if (product.category_slug){
                if (old_products[product.category_slug] == undefined){
                old_products[product.category_slug] = {
                    category_name: product.category_name,
                    products: []
                }
                }
                old_products[product.category_slug].products.push(product);
            }
        }
        setProducts(old_products);
    }

    const loadMoreProducts = () => {
        setIsFetchingMoreProducts(true);

        let apiUrl = process.env.REACT_APP_API_URL + '/location-api/' + process.env.REACT_APP_LOCATION_ID + '/product/load-more/' + offset;

        if (selectedCategory){
            apiUrl += "/category/" + selectedCategory;
        }

        let body = {
            'single_location' : true
        }

        callApiUrl('POST', apiUrl, body)
        .then(function (response) {
            if (response.product_offset < 18){
                setLoadMoreEnabled(false);
            }

            let oldProducts = products;

            updateProducts(oldProducts, response.products, response.product_offset);
            setOffset(offset + response.product_offset);

            setIsFetchingMoreProducts(false);
        })
        .catch(function (err) {
            setLoadMoreEnabled(false);
            setIsFetchingMoreProducts(false);
        });
    }

    const changeCategory = (id_category) => {
        window.scrollTo({top: 0});
        setIsLoadingProducts(true);
        let apiUrl = process.env.REACT_APP_API_URL + '/location-api/' + process.env.REACT_APP_LOCATION_ID + '/product/load-more/0';
        if (id_category == selectedCategory){
            id_category = null;
        }else{
            apiUrl += "/category/" + id_category;
        }
        setSelectedCategory(id_category);
        
        let body = {
            'single_location' : true
        }

        callApiUrl('post', apiUrl, body)
        .then(resJson => {
            let oldProducts = {};
            updateProducts(oldProducts, resJson.products, resJson.product_offset);
            setOffset(resJson.product_offset);

            if (resJson.product_offset < 18){
                setLoadMoreEnabled(false);
            }else{
                setLoadMoreEnabled(true);
            }
            setIsLoadingProducts(false);
        })
        .catch(error => {
            console.log(error)
        });
    }

    const loadProducts = () => {
        setIsLoadingProducts(true);
        let apiUrl = process.env.REACT_APP_API_URL + '/location/get/' + process.env.REACT_APP_LOCATION_ID;
            
        callApiUrl('GET', apiUrl)
        .then(function (response) {
            
            if (response.location.product_offset < 18){
              setLoadMoreEnabled(false);
            }
            
            setProducts(response.location.products_categories);
            setOffset(response.location.product_offset);
            setIsLoadingProducts(false);
        })
        .catch(function (err) {
            setIsLoadingProducts(false);
        });
    }

    const quantityDecrement = () => {
        let quantity = parseInt(focusedProductQuantity);
        if (quantity > 1){
            quantity--;


            let extras = [];
            if (options.length > 0){
                for (let key in options) {
                    if (options[key].selectedExtras != undefined && options[key].selectedExtras.length > 0){
                        extras = extras.concat(options[key].selectedExtras);
                    }
                }
            }
    
            let extrasPrice = 0;
            if (extras != []){
                for (var key in extras) {
                    extrasPrice += extras[key].price;
                }
            }
            
            let newPrice = (parseFloat(productPrice)+parseFloat(extrasPrice)) * parseInt(quantity);

            setPrice(newPrice);
    
            setFocusedProductQuantity(quantity);
        }
    }

    const quantityIncrement = () => {
        let quantity = parseInt(focusedProductQuantity);
        quantity++;


        let extras = [];
        if (options.length > 0){
            for (let key in options) {
                if (options[key].selectedExtras != undefined && options[key].selectedExtras.length > 0){
                    extras = extras.concat(options[key].selectedExtras);
                }
            }
        }

        let extrasPrice = 0;
        if (extras != []){
            for (var key in extras) {
                extrasPrice += extras[key].price;
            }
        }

        let newPrice = (parseFloat(productPrice)+parseFloat(extrasPrice)) * parseInt(quantity);
        setPrice(newPrice);

        setFocusedProductQuantity(quantity);
    }

    const changeWeight = (event) => {
        setWeightSelected(event.target.value);
        changeVariant(event.target.value, colorSelected, styleSelected);
    }

    const changeColor = (event) => {
        setColorSelected(event.target.value);
        changeVariant(weightSelected, event.target.value, styleSelected);
    }

    const changeStyle = (event) => {
        setStyleSelected(event.target.value);
        changeVariant(weightSelected, colorSelected, event.target.value);
    }

    const changeVariant = (newWeight, newColor, newStyle) => {

        setVariationChanging(true);
        let apiUrl = process.env.REACT_APP_API_URL + '/product/get-variant';

        let body = {
            id_product: focusedProduct.id_product
        };

        if (weightOptions.length > 0){
            body.weight = weightOptions[newWeight];
        }

        if (colorsOptions.length > 0){
            body.color = colorsOptions[newColor];
        }

        if (stylesOptions.length > 0){
            body.style = stylesOptions[newStyle];
        }
        
        callApiUrl('POST', apiUrl, body)
        .then(resJson => {
            if (!resJson.no_variant){
                setSelectedVariant(resJson.variant.id_product_variant);

                let extras = [];
                if (options.length > 0){
                    for (let key in options) {
                        if (options[key].selectedExtras != undefined && options[key].selectedExtras.length > 0){
                            extras = extras.concat(options[key].selectedExtras);
                        }
                    }
                }

                let extrasPrice = 0;
                if (extras != []){
                    for (var key in extras) {
                        extrasPrice += extras[key].price;
                    }
                }
                let quantity = parseInt(focusedProductQuantity);
                setProductPrice(resJson.variant.price);
                let newPrice = (parseFloat(resJson.variant.price)+parseFloat(extrasPrice))*quantity;
    
                setPrice(newPrice);
            }
            setVariationChanging(false);
        })
        .catch(error => {
            setVariationChanging(false);
        });
    }

    const selectExtra = (extraGroupIndex, extraIndex, extra) => {
        let oldOptions = [...options];

        if (oldOptions[extraGroupIndex].selectedExtras == undefined){
            oldOptions[extraGroupIndex].selectedExtras = [];
        }
        let newPrice = 0;
        if (oldOptions[extraGroupIndex].extras[extraIndex].is_selected){
            newPrice = parseFloat(price) - parseFloat(oldOptions[extraGroupIndex].extras[extraIndex].price)*focusedProductQuantity;
            for (var key in oldOptions[extraGroupIndex].selectedExtras) {
                if (oldOptions[extraGroupIndex].selectedExtras[key].id_extra == extra.id_extra) {
                    oldOptions[extraGroupIndex].selectedExtras.splice(key, 1);
                }
            }
            oldOptions[extraGroupIndex].extras[extraIndex].is_selected = null;

            setPrice(Math.round((newPrice + Number.EPSILON) * 100) / 100);
            setOptions(oldOptions);
            
        }else{
            if (oldOptions[extraGroupIndex].max == 1){
                newPrice = parseFloat(price);
                oldOptions[extraGroupIndex].selectedExtras = [];
                for (var key in oldOptions[extraGroupIndex].extras) {
                    if (oldOptions[extraGroupIndex].extras[key].is_selected){
                        oldOptions[extraGroupIndex].extras[key].is_selected = null;
                        newPrice -= parseFloat(oldOptions[extraGroupIndex].extras[key].price)*focusedProductQuantity;
                    }
                }
                
                newPrice += parseFloat(oldOptions[extraGroupIndex].extras[extraIndex].price)*focusedProductQuantity;
                oldOptions[extraGroupIndex].selectedExtras.push(extra);
                oldOptions[extraGroupIndex].extras[extraIndex].is_selected = true;

                setPrice(Math.round((newPrice + Number.EPSILON) * 100) / 100);
                setOptions(oldOptions);
                
            }else{
                let max = 1;
                if (!oldOptions[extraGroupIndex].max || oldOptions[extraGroupIndex].max == 0){
                    max = 999;
                }else{
                    max = oldOptions[extraGroupIndex].max;
                }
                if (oldOptions[extraGroupIndex].selectedExtras.length < max){
                    newPrice = parseFloat(price) + parseFloat(oldOptions[extraGroupIndex].extras[extraIndex].price)*focusedProductQuantity;
                    oldOptions[extraGroupIndex].selectedExtras.push(extra);
                    oldOptions[extraGroupIndex].extras[extraIndex].is_selected = true;

                    setPrice(Math.round((newPrice + Number.EPSILON) * 100) / 100);
                    setOptions(oldOptions);
                }
            }
        }
    }

    const addProductToCart = () => {
        let oldOptions = [...options];

        let extras = [];
        let has_error = false;

        if (oldOptions.length > 0){
            for (let key in oldOptions) {
                if (oldOptions[key].min > 0){
                    if (options[key].selectedExtras == undefined || options[key].selectedExtras.length < options[key].min){
                        oldOptions[key].has_error = true;
                        has_error = true;
                    }else{
                        oldOptions[key].has_error = false;
                    }
                }
                if (oldOptions[key].selectedExtras != undefined && oldOptions[key].selectedExtras.length > 0){
                    extras = extras.concat(oldOptions[key].selectedExtras);
                }
            }
    
            if (has_error){
                setOptions(oldOptions);
                return false;
            }
        }

        let cartProduct = {...focusedProduct};

        cartProduct.price = productPrice;

        cartProduct.quantity = focusedProductQuantity;

        cartProduct.total_price = parseFloat(price);
        
        if (extras != []){
            cartProduct.extras = extras;
        }

        if (selectedVariant != null){
            let variant = {
                id_product_variant: selectedVariant,
                quantity: focusedProductQuantity,
                price: productPrice
            };
            if (weightOptions.length > 0){
                variant.weight = weightOptions[weightSelected];
            }
            if (colorsOptions.length > 0){
                variant.color = colorsOptions[colorSelected];
            }
            if (stylesOptions.length > 0){
                variant.style = stylesOptions[styleSelected];
            }
            cartProduct.variant = variant;
        }
        
        if (focusedProductComment != ''){
            cartProduct.the_comment = focusedProductComment;
        }

        dispatch(addToCart(cartProduct));
        dispatch(showNotification());
        setTimeout(() => {
            dispatch(hideNotification());
        }, 3000);
        resetModal();
    }

    const resetModal = () => {
        setFocusedProductComment('');
        setFocusedProductQuantity(1);
        setWeightSelected(0);
        setColorSelected(0);
        setStyleSelected(0);
        setOptions([]);
        closeModal();
    }
    
    React.useEffect(() => {
        loadProducts();
    }, []);

    let location_img = '';
    if (location.img_slug){
        location_img = process.env.REACT_APP_ASSETS_URL + location.img_slug;
    }

    let shipping_delay_label = '';
    let pickup_delay_label = '';

    if (location.schedule){
        if (location.schedule.shipping_delay < 1){
            let pickup_delay = location.schedule.shipping_delay*60;
            let shipping_delay = pickup_delay+15;
            pickup_delay_label = '~' + pickup_delay.toFixed(0) +'mins';
            shipping_delay_label = '~' + shipping_delay.toFixed(0) +'mins';
        }else{
            shipping_delay_label = '~' + location.schedule.shipping_delay +'h';
            pickup_delay_label = '~' + location.schedule.shipping_delay +'h';
        }
    }

    return (
        <div>
            <section className="hero-category" style={{position: "relative"}}>
                {
                    isLoadingLocation ? (
                        <div>
                            <p className="placeholder-item" style={{marginTop: '1em', width: '100%', height: '10em'}}>

                            </p>
                            
                            <p className="placeholder-item" style={{marginTop: '2em', width: '60%'}}>

                            </p>
                            <p className="placeholder-item" style={{marginTop: '1em', width: '50%'}}>

                            </p>
                        </div>
                    ) : (
                        <div>
                            <img src={location_img} />
                            <h1 className="title">{process.env.REACT_APP_LOCATION_NAME}</h1>
                            <p>
                                {
                                location.slogan ? (
                                    <>{location.slogan}</>
                                ) : (
                                    <>Commande en ligne</>
                                )
                            }
                            <span className="is-hidden-mobile"> &#8226; </span><span className={`is-block-mobile ${location.is_open == 1 && location.order_unavailable == 0 ? "has-text-success" : "has-text-danger"}`}><b>{location.delivery_message}</b></span> 
                            <span className={`is-block-mobile`}><span className="is-hidden-mobile"> &#8226; </span>Frais livraison: {location.white_label_shipping_cost ? location.white_label_shipping_cost == 0 ? 'Gratuit' : location.white_label_shipping_cost + '$' : location.shipping_cost ? location.shipping_cost + '$' : '3.99$'}</span> 
                            </p>
                            <div className="delays-n-prices">
                                <div class="field has-addons">
                                    <p class="control">
                                        <button onClick={() => {if (!is_pickup){dispatch(setPickup(true))}}} className={`button ${is_pickup ? "is-primary" : ""}`}>
                                        Emporter&nbsp;<small>({pickup_delay_label})</small>
                                        </button>
                                    </p>
                                    <p class="control">
                                        <button onClick={() => {if (is_pickup){dispatch(setPickup(false))}}} className={`button ${!is_pickup ? "is-primary" : ""}`}>
                                        Livraison&nbsp;<small>({shipping_delay_label})</small>
                                        </button>
                                    </p>
                                </div>
                                {
                                    is_pickup ? (
                                        <p><small>Présentez vous au <b>{location.address}</b></small></p>
                                    ) : user && user.shipping_address.address ? (
                                        <p><small>Livraison au : <b>{user.shipping_address.address}, {user.shipping_address.city}</b></small></p>
                                    ) : (
                                        <p onClick={() => setShowAdressModal(true)} className="clickable-link"><small>Entrez votre adresse de livraison</small></p>
                                    )
                                }
                                <p></p>
                                {/* <span className="is-block-mobile"> <b>Livraison:</b> {location.white_label_shipping_cost ? location.white_label_shipping_cost : 3.99}$, {shipping_delay_label}</span>
                                {
                                    location.offer_delivery ? (
                                        <span className="is-block-mobile"><span className="is-hidden-mobile"> &#8226;</span> <b>Ramassage:</b> {pickup_delay_label}</span>
                                    ) : null
                                } */}
                            </div>
                        </div>
                    )
                }
            </section>
            <section>
                <div id="mobile-filter-tags" className="is-hidden-tablet container">
                    <div>
                        <p className="label" style={{marginRight: '10px'}} onClick={() => setShowMobileFilters(!showMobileFilters)}>
                            <i className="icon-list-nested"></i>&nbsp;Cat&eacute;gories:
                        </p>
                    </div>
                    <div className="mobile-filter-wrapper">
                        {
                        isLoadingLocation ? (
                            <p className="placeholder-item" style={{width: '85%', height: '2em'}}>

                            </p>
                        ) : (
                            <div className="tags">
                                {location.categories.map((category, index) => (
                                <span key={index} className={`tag is-medium is-primary ${selectedCategory != category.id_category ? "is-light" : ""}`} onClick={() => changeCategory(category.id_category)}>
                                    {category.name} {selectedCategory == category.id_category ? ( <span style={{float: 'right'}}><i className="icon-cancel" /></span> ) : null}
                                </span>
                                ))}
                            </div>
                        )
                        }
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-one-third">
                        <div className="box is-hidden-mobile">
                            <p className="label is-size-5">
                                Horaire
                            </p>
                            <hr className="is-hidden-mobile" style={{marginTop: '0.5em', marginBottom: '0.5em'}} />
                            <aside className="menu">
                                {
                                    isLoadingLocation ? (
                                        <div>
                                            <p className="placeholder-item" style={{width: '85%'}}>
            
                                            </p>
                                            <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
            
                                            </p>
                                            <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
            
                                            </p>
                                            <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
            
                                            </p>
                                        </div>
                                    ) : (
                                        <ul className="menu-list">
                                            {schedule.map((day, index) =>
                                                <div key={index} className="columns is-mobile" style={{maxWidth: 300, marginBottom: 0}}>
                                                    <div className="column">
                                                        <p style={day.day_is_today ? {fontWeight: 'bold'} : null}>{day.label}</p>
                                                    </div>
                                                    <div className="column">
                                                        <p style={day.day_is_today ? {fontWeight: 'bold'} : null}>{day.day_closed ? 'Fermé' : day.start + ' - ' + day.end}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </ul>
                                    )
                                }
                            </aside>
                        </div>
                        <div id="mobile-filter-box" className={`sticky ${showMobileFilters ? "is-active" : ""}`}>
                            <div className="box">
                                <div className="is-hidden-tablet has-text-right">
                                    <button onClick={() => setShowMobileFilters(!showMobileFilters)} className="button is-danger">
                                        <i className="icon-cancel" />
                                    </button>
                                    <hr/>
                                </div>
                                <p className="label is-size-5">
                                    Catégories
                                </p>
                                <hr className="is-hidden-mobile" style={{marginTop: '0.5em', marginBottom: '0.5em'}} />
                                <aside className="menu">
                                    {
                                        isLoadingLocation ? (
                                            <div>
                                                <p className="placeholder-item" style={{width: '85%'}}>
                
                                                </p>
                                                <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
                
                                                </p>
                                                <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
                
                                                </p>
                                                <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
                
                                                </p>
                                            </div>
                                        ) : (
                                            <ul className="menu-list">
                                                {location.categories.map((category, index) => (
                                                <li key={index}>
                                                    <a className={selectedCategory == category.id_category ? "is-active" : ""} onClick={() => changeCategory(category.id_category)}>
                                                        {category.name} {selectedCategory == category.id_category ? ( <span style={{float: 'right'}}><i className="icon-cancel" /></span> ) : null}
                                                    </a>
                                                </li>
                                                ))}
                                            </ul>
                                        )
                                    }
                                </aside>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        {
                            isLoadingProducts ? (
                                <LoadingLocations />
                            ) : (
                                <div>
                                    <ProductCategoryList products={products} />
                                    {
                                        loadMoreEnabled ? (
                                            <p className="has-text-centered">
                                                <button className={`button is-primary ${isFetchingMoreProducts ? "is-loading" : ""}`} onClick={loadMoreProducts}>Voir plus de produits</button>
                                            </p>
                                        ) : null
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            <div id="options-modal" className={`modal ${productModalVisible ? "is-active" : ""}`}>
                <div className="modal-background" onClick={resetModal}></div>
                <div className="modal-content">
                    <div className="box">
                        {
                            isModalFetching ? (
                            <div>
                                <p className="placeholder-item" style={{width: '100%', height: '10em'}}>

                                </p>

                                <p className="placeholder-item" style={{marginTop: '2em', width: '60%'}}>

                                </p>
                                <p className="placeholder-item" style={{marginTop: '1em', width: '50%'}}>

                                </p>
                            </div>
                            ) : (
                            <div>
                                {focusedProduct.images ? (
                                    <div className="has-text-centered">
                                        <img id="product-img-img" src={process.env.REACT_APP_ASSETS_URL + focusedProduct.images} />
                                    </div>
                                ) : null }
                                <div>
                                    <h2 className="title">{focusedProduct.name}</h2>
                                    {focusedProduct.description ? (
                                        <div>
                                            <hr/>
                                            <div id="product-description">{focusedProduct.description}</div>
                                        </div>
                                    ) : null }
                                    <div id="variant-options">
                                    {
                                    optionsEnabled && focusedProduct.has_variants == 1 ? (
                                        <div>
                                        <hr/>
                                            <div className="columns">
                                            {
                                            weightOptions.length > 0 ? (
                                                <div className="column">
                                                    <div className="select">
                                                        <select name="weight" value={weightSelected} onChange={changeWeight}>
                                                        {weightOptions.map((weight, index) => (
                                                            <option key={index} value={index}>{weight}</option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null
                                            }
                                            {
                                            colorsOptions.length > 0 ? (
                                                <div className="column">
                                                    <div className="select">
                                                        <select name="color" value={colorSelected} onChange={changeColor}>
                                                        {colorsOptions.map((color, index) => (
                                                            <option key={index} value={index}>{color}</option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null
                                            }
                                            {
                                            stylesOptions.length > 0 ? (
                                                <div className="column">
                                                    <div className="select">
                                                        <select name="style" value={styleSelected} onChange={changeStyle}>
                                                        {stylesOptions.map((style, index) => (
                                                            <option key={index} value={index}>{style}</option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            ) : null
                                            }
                                            </div>
                                        </div>
                                    ) : null
                                    }
                                    </div>
                                    {
                                    optionsEnabled && focusedProduct.has_extras == 1 ? (
                                        <div id="extras-options">
                                            <hr/>
                                            {options.map((extra_group, group_index) => {
                                                let multiple = true;
                                                let is_required = false;
                                                let max_choice = false;
                                                let extra_message = '';
                                                if (extra_group.min > 0){
                                                    is_required = true;
                                                    extra_message += '* Choix obligatoire';
                                                    if (extra_group.min == 1 && extra_group.max == 1){
                                                        multiple = false;
                                                        extra_message += ', maximum: 1';
                                                    }else{
                                                        extra_message += ', minimum: ' + extra_group.min + ',  maximum: ' + extra_group.max;
                                                    }
                                                }else{
                                                    if (extra_group.max > 0){
                                                        max_choice = true;
                                                        extra_message += '* Choix maximum: ' + extra_group.max;
                                                    }
                                                }
                                                let error_class = extra_group.has_error ? "has-background-danger has-text-white" : null;
                                    
                                                return (
                                                    <div key={group_index} className="extra-group">
                                                        <label className="label" style={{marginBottom: '1em'}}>
                                                            {extra_group.name}
                                                            {
                                                                extra_message ? (
                                                                    <span>
                                                                        <br />
                                                                        <small className={error_class} style={{color: 'gray'}}>{extra_message}</small>
                                                                    </span>
                                                                ) : null
                                                            }
                                                        </label>
                                                        {extra_group.extras.map((extra, extra_index) => {
                                                            let is_selected = extra.is_selected ? true : false;
                                                            return (
                                                                <div key={extra_index} className="columns is-mobile">
                                                                    <div className="column is-9">
                                                                        <label className="checkbox is-column" htmlFor={`extra-${extra.id_extra}`} >
                                                                            {
                                                                                multiple ? (
                                                                                    <input type="checkbox" checked={is_selected} className="extras-checkbox" id={`extra-${extra.id_extra}`} name={`extra_${extra_group.id_extra_group}`} onChange={() => selectExtra(group_index, extra_index, extra)} />
                                                                                ) : (
                                                                                    <input type="radio" checked={is_selected} className="extras-checkbox" id={`extra-${extra.id_extra}`} name={`extra_${extra_group.id_extra_group}`} onChange={() => selectExtra(group_index, extra_index, extra)} />
                                                                                )
                                                                            } {extra.name}
                                                                        </label>
                                                                    </div>
                                                                    <div className="column has-text-right">
                                                                    {
                                                                        extra.price > 0 ? (
                                                                            <span>{extra.price}$</span>
                                                                        ) : null
                                                                    }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : null
                                    }
                                </div>
                                {
                                    optionsEnabled ? (
                                        <div>
                                            <div id="comment-options">
                                                <hr/>
                                                <small>Consignes particulières (facultatif)</small>
                                                <textarea 
                                                    id="comment" 
                                                    name="comment" 
                                                    className="textarea is-small" 
                                                    placeholder="Commentaire"
                                                    onChange={(e) => setFocusedProductComment(e.target.value)}
                                                    value={focusedProductComment}
                                                ></textarea>
                                            </div>
                                            <br/>
                                            <div className="field has-addons">
                                                <p className="control">
                                                    <button className="button" onClick={quantityDecrement}>
                                                        <i className="icon-minus" />
                                                    </button>
                                                </p>
                                                <p className="control is-expanded">
                                                    <input className="input" type="text" value={focusedProductQuantity} readOnly />
                                                </p>
                                                <p className="control">
                                                    <button className="button" onClick={quantityIncrement}>
                                                        <i className="icon-plus" />
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <br/>
                                <div className="columns is-mobile">
                                    <div className="column is-4" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <p className="has-text-centered is-size-5">
                                            {
                                                variationChanging ? (
                                                    <span className="placeholder-item" style={{minWidth: '80px', display: 'block'}}></span>
                                                ) : (
                                                    <strong>{price.toFixed(2)}$</strong>
                                                )
                                            }
                                        </p>
                                    </div>
                                    <div className="column">
                                        <p id="variant-error-message" className="has-text-danger has-text-centered error-message"></p>
                                        <p className="has-text-centered">
                                            {
                                            optionsEnabled ? (
                                                <button id="add-variant-cart" className="button is-primary is-fullwidth" onClick={addProductToCart} >
                                                    Ajouter au panier
                                                </button>
                                            ) : (
                                                <button id="add-variant-cart" className="button is-fullwidth" disabled>
                                                    Indisponible
                                                </button>
                                            )
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                    </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={resetModal}></button>
            </div>
            <Modal 
                onClose={() => { setShowAdressModal(false) }} 
                show={showAdressModal}>
                <div>
                    <h2 className="has-text-centered mt-2 mb-4">Entrez votre adresse</h2>
                    <hr/>
                    {
                        isLoaded ? (
                            <PlacesAutocomplete setSelected={onPlaceSelected} parentValue={address} />
                        ) : null
                    }
                    {
                        address ? (
                        <div>
                            <hr/>
                            <div className="columns">
                                <div className="column is-two-thirds">
                                    <div className="field">
                                        <label className="label">Adresse civique</label>
                                        <div className="control">
                                            <input 
                                                className="input" 
                                                type="text" 
                                                id="address" 
                                                name="address" 
                                                placeholder="Adresse civique"
                                                onChange={(e) => setAddress(e.target.value)}
                                                value={address}
                                            / >
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Appartement</label>
                                        <div className="control">
                                            <input 
                                                className="input" 
                                                type="text" 
                                                id="appartment" 
                                                name="appartment" 
                                                placeholder="Appartement"
                                                onChange={(e) => setAppartment(e.target.value)}
                                                value={appartment}
                                            / >
                                        </div>
                                    </div>
                                </div>
                            </div>
                
                            <div className="columns">
                                <div className="column is-two-thirds">
                                    <div className="field">
                                        <label className="label">Ville</label>
                                        <div className="control">
                                            <input 
                                                className="input" 
                                                type="text" 
                                                id="city" 
                                                name="city" 
                                                placeholder="Ville"
                                                onChange={(e) => setCity(e.target.value)}
                                                value={city}
                                            / >
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="field">
                                        <label className="label">Code Postal</label>
                                        <div className="control">
                                            <input 
                                                className="input" 
                                                type="text" 
                                                id="postal_code" 
                                                name="postal_code" 
                                                placeholder="Code postal"
                                                onChange={(e) => setPostalCode(e.target.value)}
                                                value={postalCode}
                                            / >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <label>
                                <input id="save_profile" name="save_profile" type="checkbox" checked={persistUser} onChange={handlePersistChange}/>
                                &nbsp;Sauvegarder mes informations
                            </label>
                            <br/><br/>
                            <p className="has-text-centered" onClick={saveAddress}><button className="button is-primary">Enregistrer</button></p>
                        </div>
                        ) : null
                    }
                    <br/>
                </div>
            </Modal>
        </div>
    );
}

export default Location;
