import { createSlice, configureStore } from '@reduxjs/toolkit'

const saveCart = (cart) => {
  try {
    const serializedCart = JSON.stringify(cart);
    localStorage.setItem('cart', serializedCart);
  } catch {
    // ignore write errors
  }
};

const persistUser = (user) => {
  try {
    const serializedUser = JSON.stringify(user);
    localStorage.setItem('user', serializedUser);
  } catch {
    // ignore write errors
  }
};

const loadState = () => {
  try {
    const cart = JSON.parse(localStorage.getItem('cart'));
    const user = JSON.parse(localStorage.getItem('user'));

    let initialState = {
      user: {
        shipping_address: {}
      },
      cart_notification: false,
      is_pickup: true,
      location: {},
      isLoadingLocation: true
    };

    
    if (cart === null) {
      initialState.cart = {
        products: [],
        count: 0,
        subTotal: 0
      };
    }else{
      initialState.cart = cart;
    }

    if (user === null){
      initialState.user = {
        shipping_address: {}
      };
    }else{
      initialState.user = user;
    }

    return initialState;
  } catch (err) {
    return undefined;
  }
};


const deleteSavedCart = () => {
  try {
    localStorage.removeItem('cart');
  } catch (err) {
    //
  }
};


const counterSlice = createSlice({
  name: 'store',
  initialState: {
    user: {
      shipping_address: {}
    },
    cart: {
        products: [],
        count: 0,
        subTotal: 0
    },
    is_pickup: true,
    cart_notification: false,
    location: {},
    schedule: [],
    isLoadingLocation: true
  },
  reducers: {
    setLocation: (state, action) => {
        state.location = action.payload;

        let schedule = action.payload.schedule;

        var d = new Date();
        var dayName = d.getDay();

        let days = [];
        for (let x = 1; x <= 7; x++) {
            let day_is_today = false;

            if (x === dayName){
                day_is_today = true;
            }

            let label = "";
            let db_day = "";

            switch (x) {
                case 1:
                    label = "Lundi";
                    db_day = "mon";
                    break;
                case 2:
                    label = "Mardi";
                    db_day = "tue";
                    break;
                case 3:
                    label = "Mercredi";
                    db_day = "wed";
                    break;
                case 4:
                    label = "Jeudi";
                    db_day = "thu";
                    break;
                case 5:
                    label = "Vendredi";
                    db_day = "fri";
                    break;
                case 6:
                    label = "Samedi";
                    db_day = "sat";
                    break;
                case 7:
                    label = "Dimanche";
                    db_day = "sun";
                    break;
                default:
                    label = "Lundi";
                    db_day = "mon";
                    break;
            }
            
            let day_closed = false;
            let start = '0:00';
            let end = '23:59';

            if (schedule){
                if (schedule[db_day] === "1"){
                    start = schedule[db_day+"_start"].split(':');
                    start = start[0] + ':' + start[1];
                    end = schedule[db_day+"_end"].split(':');
                    end = end[0] + ':' + end[1];
                }else{
                    day_closed = true;
                }
            }else{
                day_closed = true;
            }
            
            let array_date = {
                label: label,
                day_closed: day_closed,
                start: start,
                end: end,
                day_is_today: day_is_today
            }

            days.push(array_date);
        }

        state.schedule = days;

        state.isLoadingLocation = false;
    },
    addToCart: (state, action) => {
        let oldCart = {...state.cart};

        let product = {...action.payload};
        
        oldCart.products.push(product);
        let quantity = product.variant ? product.variant.quantity : product.quantity;

        oldCart.count += parseInt(quantity);
        oldCart.subTotal = parseFloat(oldCart.subTotal) + parseFloat(product.total_price);

        oldCart.subTotal = oldCart.subTotal.toFixed(2);

        state.cart = oldCart;
        saveCart(oldCart);
    },
    quantityIncrement: (state, action) => {
        let oldCart = {...state.cart};

        if (oldCart.products[action.payload].variant){
            oldCart.products[action.payload].variant.quantity++;
        }else{
            oldCart.products[action.payload].quantity++;
        }
        oldCart.count ++;
        
        let oldProductPrice = oldCart.products[action.payload].total_price;

        let extrasPrice = 0;
        if (oldCart.products[action.payload].extras){
            for (var key in oldCart.products[action.payload].extras) {
                extrasPrice += parseFloat(oldCart.products[action.payload].extras[key].price);
            }
        }
        
        let productPrice = oldCart.products[action.payload].variant ? oldCart.products[action.payload].variant.price : oldCart.products[action.payload].price;
        let productQuantity = oldCart.products[action.payload].variant ? oldCart.products[action.payload].variant.quantity : oldCart.products[action.payload].quantity;

        let newProductPrice = (parseFloat(productPrice)+parseFloat(extrasPrice)) * parseInt(productQuantity);
        oldCart.products[action.payload].total_price = newProductPrice;

        oldCart.subTotal = parseFloat(oldCart.subTotal) - parseFloat(oldProductPrice) + parseFloat(newProductPrice);

        oldCart.subTotal = oldCart.subTotal.toFixed(2);

        state.cart = oldCart;
        saveCart(oldCart);
    },
    quantityDecrement: (state, action) => {
        let oldCart = {...state.cart};

        let oldProductPrice = oldCart.products[action.payload].total_price;
        oldCart.count --;
        let newProductPrice = 0;

        let hasVariant = oldCart.products[action.payload].variant ? true : false;
        let productPrice = hasVariant ? oldCart.products[action.payload].variant.price : oldCart.products[action.payload].price;
        let productQuantity = hasVariant ? oldCart.products[action.payload].variant.quantity : oldCart.products[action.payload].quantity;
        
        if (productQuantity == 1){
            oldCart.products.splice(action.payload, 1);
        }else{
            if (hasVariant){
                oldCart.products[action.payload].variant.quantity--;
            }else{
                oldCart.products[action.payload].quantity--;
            }

            let extrasPrice = 0;
            if (oldCart.products[action.payload].extras){
                for (var key in oldCart.products[action.payload].extras) {
                    extrasPrice += parseFloat(oldCart.products[action.payload].extras[key].price);
                }
            }
            
            newProductPrice = (parseFloat(productPrice)+parseFloat(extrasPrice)) * parseInt(productQuantity);
            oldCart.products[action.payload].total_price = newProductPrice;

        }
        oldCart.subTotal = parseFloat(oldCart.subTotal) - parseFloat(oldProductPrice) + parseFloat(newProductPrice);

        oldCart.subTotal = oldCart.subTotal.toFixed(2);

        state.cart = oldCart;
        saveCart(oldCart);
    },
    deleteProduct: (state, action) => {
        let oldCart = {...state.cart};

        let oldProductPrice = oldCart.products[action.payload].total_price;

        let hasVariant = oldCart.products[action.payload].variant ? true : false;
        let productQuantity = hasVariant ? oldCart.products[action.payload].variant.quantity : oldCart.products[action.payload].quantity;

        oldCart.count -= productQuantity;

        oldCart.products.splice(action.payload, 1);
        
        oldCart.subTotal = parseFloat(oldCart.subTotal) - parseFloat(oldProductPrice);

        oldCart.subTotal = oldCart.subTotal.toFixed(2);

        state.cart = oldCart;
        saveCart(oldCart);
    },
    emptyCart(state, action){
        let newCart =  {
          products: [],
          count: 0,
          subTotal: 0
        };
        state.cart = newCart;
        deleteSavedCart();
    },
    saveUser(state, action){
        state.user = action.payload.user;

        if (action.payload.persist){
          persistUser(action.payload.user);
        }
    },
    showNotification: (state, action) => {
        state.cart_notification = true;
    },
    hideNotification: (state, action) => {
        state.cart_notification = false;
    },
    setPickup: (state, action) => {
        state.is_pickup = action.payload;
    }
  }
})

export const { setLocation, setPickup, addToCart, quantityIncrement, quantityDecrement, deleteProduct, emptyCart, showNotification, hideNotification, saveUser } = counterSlice.actions

const store = configureStore({
  reducer: counterSlice.reducer,
  preloadedState: loadState()
});

export default store;