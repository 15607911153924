import React from 'react';
import { useParams } from "react-router-dom";

function Track() {
    
    let { invoiceNumber } = useParams();

    return (
        <div>
            <div className="panel is-success" style={{maxWidth: '600px', margin: 'auto'}}>
                <p className="panel-heading">
                    Merci pour votre commande!
                </p>
                <div className="panel-block">
                    <p>
                        Votre commande est confirmé!<br/><br/>
                        Voici le numéro de votre commande:<br/><br/>
                        <span className="is-size-5"><strong>{invoiceNumber}</strong></span><br/><br/>
                        Vous recevrez des notifications par <b>SMS</b> lors des différentes étapes de préparation de la commande.<br/><br/>
                        Bon appétit!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Track;
