
function Modal(props) {

    const onClose = () => {
        props.onClose && props.onClose();
    }
    
    let modalClasses = "modal";
    if (props.show) {
        modalClasses += " is-active";
    }

    return (
        <div className={modalClasses} id="modal">
            <div className="modal-background" onClick={onClose}></div>
            <div className="modal-content">
                <div className="box">
                    <div className="content">
                        <div className="has-text-right">
                            <button className={`button`} onClick={() => onClose()}>
                                &#10006;
                            </button>
                        </div>
                        {props.children}
                    </div>
                </div> 
            </div>
            <button className="modal-close is-large is-hidden-mobile" aria-label="close" onClick={onClose}></button>
        </div>
    );
}

export default Modal;