import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import {callApiUrl} from '../utils/functions'
import { quantityIncrement, quantityDecrement, deleteProduct, emptyCart, saveUser, setPickup } from '../utils/store'
import { useNavigate } from "react-router-dom";

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontSmoothing: "antialiased",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
    hidePostalCode: true
};

function Checkout(props) {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const location = useSelector(state => state.location);
    const user = useSelector(state => state.user);
    const is_pickup = useSelector(state => state.is_pickup);
    const stripe = useStripe();
    const elements = useElements();

    const productQuantityDecrement = (product_index) => {
        dispatch(quantityDecrement(product_index));
    }

    const productQuantityIncrement = (product_index) => {
        dispatch(quantityIncrement(product_index));
    }

    const deleteItem = (product_index) => {
        dispatch(deleteProduct(product_index));
    }

    function Variant(props) {
        let variant = props.variant;

        let title = '';
        var only_one_option = true;
        
        if (variant.weight != undefined)
        {
            title += variant.weight;
            only_one_option = false;
        }
        
        if (variant.color != undefined)
        {
            if (!only_one_option){
                title += " / ";
            }
            title += variant.color;
            only_one_option = false;
        }
        
        if (variant.style != undefined)
        {
            if (!only_one_option){
                title += " / ";
            }
            title += variant.style;
            only_one_option = false;
        }

        return (
            <p><small>&#8226; {title}</small></p>
        );
    }

    function Extras(props) {
        const extrasItems = props.extras.map((extra, index) => {
            return (
                <div key={index} className="columns is-mobile" style={{marginBottom: 0}}>
                    <div className="column">
                        <p><small>&#8226; {extra.name}</small></p>
                    </div> 
                    <div className="column has-text-right is-3">
                        {extra.price}$
                    </div>
                </div>
            )
        });
    
        return extrasItems;
    }

    function Products() {

        const productsItems = cart.products.map((product, index) => {
            return (
                <div key={index} className="product">
                    <div className="columns is-mobile" style={{marginBottom: 0}}>
                        <div className="column">
                            <p><strong>{ step == 2 ? ( <>{product.variant ? (<span>{product.variant.quantity}x</span>) : (<span>{product.quantity}x</span>) } </> ) : null } {product.name}</strong></p>
                            {
                            product.variant ? (
                                <Variant variant={product.variant} />
                            ): null
                            }
                            {
                                product.the_comment ? (
                                    <p style={{marginTop: 5}}><small>Commentaire : {product.the_comment}</small></p>
                                ) : null
                            }
                        </div> 
                        <div className="column has-text-right is-3">
                            {parseFloat(product.price).toFixed(2)}$
                        </div>
                    </div>
                    {
                        product.extras ? (
                            <Extras extras={product.extras} />
                        ): null
                    }
                    {
                        step != 2 ? (
                            <div className="columns is-mobile" style={{marginBottom: 0}}>
                                <div className="column">
                                    <div className="field has-addons" style={{maxWidth: '200px'}}>
                                        <p className="control">
                                            <button className="button is-small" onClick={() => productQuantityDecrement(index)}>
                                                <i className="icon-minus" />
                                            </button>
                                        </p>
                                        <p className="control is-expanded">
                                            <input className="input is-small" type="text" value={product.variant ? product.variant.quantity : product.quantity} readOnly />
                                        </p>
                                        <p className="control">
                                            <button className="button is-small" onClick={() => productQuantityIncrement(index)}>
                                                <i className="icon-plus" />
                                            </button>
                                        </p>
                                    </div>
                                </div>
                                <div className="column has-text-right is-3">
                                    <button className="button is-small" onClick={() => deleteItem(index)}><i className="icon-cancel" /></button>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            )
        });
    
        return (
            <div>
                <hr/>
                {productsItems}
                <hr/>
            </div>
        );
    }

    const goToStep2 = () => {
        
        if (surname == "" || !surname){
            setInfoError('Veuillez spécifiez votre prénom');
            return false;
        }
        
        if (name == "" || !name){
            setInfoError('Veuillez spécifiez votre nom');
            return false;
        }

        if (email == "" || !email){
            setInfoError('Veuillez spécifiez votre courriel');
            return false;
        }

        if (phone == "" || !phone){
            setInfoError('Veuillez spécifiez votre téléphone. Nous utiliserons ce numéro pour vous appeler lors de la livraison en cas de problème.');
            return false;
        }

        if (!isPickup){
            if (address == "" || !address){
                setInfoError('Veuillez spécifiez votre adresse');
                return false;
            }
            
            if (city == "" || !address){
                setInfoError('Veuillez spécifiez votre ville');
                return false;
            }
            
            if (postalCode == "" || !postalCode){
                setInfoError('Veuillez spécifiez votre code postal');
                return false;
            }
        }

        setInfoError('');

        loadShippingCost();
    }

    const goBack = (toStep, goToTop = true) => {
        setStep(toStep);
        if (goToTop){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    const handlePersistChange = (e) => {
        const target = e.target;
        const value = target.checked;
        setPersistUser(value);
    }

    const [surname, setSurname] = React.useState(user ? user.surname : '');
    const [name, setName] = React.useState(user ? user.name : '');
    const [email, setEmail] = React.useState(user ? user.email : '');
    const [phone, setPhone] = React.useState(user ? user.phone : '');
    const [address, setAddress] = React.useState(user.shipping_address.address ? user.shipping_address.address : '');
    const [appartment, setAppartment] = React.useState(user.shipping_address.appartment ? user.shipping_address.appartment : '');
    const [city, setCity] = React.useState(user.shipping_address.city ? user.shipping_address.city : '');
    const [postalCode, setPostalCode] = React.useState(user.shipping_address.postal_code ? user.shipping_address.postal_code : '');
    const [isPickup, setIsPickup] = React.useState(is_pickup);
    const [infoError, setInfoError] = React.useState('');
    const [deliveryComment, setDeliveryComment] = React.useState('');
    const [persistUser, setPersistUser] = React.useState(true);
    
    const [step, setStep] = React.useState(1);
    const [isFetching, setIsFetching] = React.useState(false);

    // shipping
    const [shippingCost, setShippingCost] = React.useState(0);
    const [closedMessage, setClosedMessage] = React.useState('');
    const [nextDeliveryRates, setNextDeliveryRates] = React.useState([]);
    const [shippingMethod, setShippingMethod] = React.useState({});
    const [asapShippingMethod, setAsapShippingMethod] = React.useState({});
    const [hours, setHours] = React.useState([]);
    const [selectedDate, setSelectedDate] = React.useState();
    const [selectedDateIndex, setSelectedDateIndex] = React.useState(0);
    const [selectedHour, setSelectedHour] = React.useState();
    const [selectedHourIndex, setSelectedHourIndex] = React.useState(0);
    const [selectedShippingMethod, setSelectedShippingMethod] = React.useState();

    const loadShippingCost = () => {
        let apiUrl = process.env.REACT_APP_API_URL + '/checkout/shipping/single-location';

        let shipping_address = {
            address: address,
            appartment: appartment,
            city: city,
            state: 'QC',
            country: 'Canada',
            postal_code: postalCode
        }

        let user = {
            surname: surname,
            name: name,
            email: email,
            phone: phone,
            shipping_address: shipping_address
        }
        
        dispatch(saveUser({user: user, persist: persistUser}));

        let body = {
            cart: cart,
            shipping_address: shipping_address,
            order_type: isPickup ? 'pickup' : 'delivery'
        };

        setIsFetching(true);
        callApiUrl('POST', apiUrl, body)
        .then(function (resJson) {
            if (resJson.base_rate){
                let baseRate = parseFloat(resJson.base_rate);
                let subTotal = parseFloat(cart.subTotal);
                setShippingCost(resJson.base_rate);
                let productTaxes = parseFloat(resJson.product_taxes);
                
                let tip = isPickup ? 0 : (subTotal + baseRate)*0.15;
                let newTaxes = ((baseRate)*0.14975)+productTaxes;
                setTip(tip.toFixed(2));
                setTaxes(newTaxes);
                setTotal(baseRate+subTotal+tip+newTaxes);
            }

            if (resJson.no_shipping){
                setOrderError(resJson.message);
            }else{
                setOrderError('');
            }
            
            if (resJson.closed == true){
                setClosedMessage('Le commerce est actuellement fermé, veuillez planifier votre commande pour plus tard.');
                setShippingMethod({
                    'distances': resJson.distances,
                    'lc': resJson.lc,
                    'cost': resJson.base_rate,
                    'delivery_estimate': resJson.next_delivery_rates[0][2] + ' ' + resJson.next_delivery_rates[0][3][0] + ':00:00',
                    'delivery_label': resJson.next_delivery_rates[0][0] + ', ' + resJson.next_delivery_rates[0][3][0] + 'h',
                    'label': 'Planifier',
                    'description': 'Planifiee'
                });
                setSelectedShippingMethod('Planifiee');
            }else{
                let asap = {
                    'distances': resJson.distances,
                    'lc': resJson.lc,
                    'cost': resJson.base_rate,
                    'delivery_estimate': resJson.rates[0].delivery_estimate + ':00:00',
                    'delivery_label': resJson.rates[0].delivery_label,
                    'label': resJson.rates[0].description,
                    'description': resJson.rates[0].description
                };
                setSelectedShippingMethod('Aussitôt que possible');
                setShippingMethod(asap);
                setAsapShippingMethod(asap);
            }
            setNextDeliveryRates(resJson.next_delivery_rates);
            setHours(resJson.next_delivery_rates[0][3]);
            setSelectedHour(resJson.next_delivery_rates[0][3][0]);
            setIsFetching(false);
            setStep(2);
            window.scrollTo({top: 0, behavior: 'smooth'});
        })
        .catch(error => {
            setIsFetching(false);
        });
    }

    const changePlannedDate = (event) => {
        let itemIndex = event.target.value;
        setHours(nextDeliveryRates[itemIndex][3]);
        setSelectedHour(nextDeliveryRates[itemIndex][3][0]);
        setSelectedHourIndex(0);
        setSelectedDate(nextDeliveryRates[itemIndex][2]);
        setSelectedDateIndex(itemIndex);

        setShippingMethod({
            'distances': shippingMethod.distances,
            'lc': shippingMethod.lc,
            'cost': shippingMethod.cost,
            'delivery_estimate': nextDeliveryRates[itemIndex][2] + ' ' + nextDeliveryRates[itemIndex][3][0] + ':00:00',
            'delivery_label': nextDeliveryRates[itemIndex][0] + ', ' + nextDeliveryRates[itemIndex][3][0] + 'h',
            'label': 'Planifier',
            'description': 'Planifiee'
        });
    }

    const changePlannedHour = (event) => {
        let itemIndex = event.target.value;
        setSelectedHour(nextDeliveryRates[selectedDateIndex][3][itemIndex]);
        setSelectedHourIndex(itemIndex);

        setShippingMethod({
            'distances': shippingMethod.distances,
            'lc': shippingMethod.lc,
            'cost': shippingMethod.cost,
            'delivery_estimate': nextDeliveryRates[selectedDateIndex][2] + ' ' + nextDeliveryRates[selectedDateIndex][3][itemIndex] + ':00:00',
            'delivery_label': nextDeliveryRates[selectedDateIndex][0] + ', ' + nextDeliveryRates[selectedDateIndex][3][itemIndex] + 'h',
            'label': 'Planifier',
            'description': 'Planifiee'
        });
    }

    const saveShippingMethod = (shipping_method) => {
        setSelectedShippingMethod(shipping_method);
        if (shipping_method == 'Aussitôt que possible') {
            setShippingMethod(asapShippingMethod);
        }else{
            setShippingMethod({
                'distances': shippingMethod.distances,
                'lc': shippingMethod.lc,
                'cost': shippingMethod.cost,
                'delivery_estimate': nextDeliveryRates[selectedDateIndex][2] + ' ' + nextDeliveryRates[selectedDateIndex][3][selectedHourIndex] + ':00:00',
                'delivery_label': nextDeliveryRates[selectedDateIndex][0] + ', ' + nextDeliveryRates[selectedDateIndex][3][selectedHourIndex] + 'h',
                'label': 'Planifier',
                'description': 'Planifiee'
            });
        }
    }

    // promo code
    const [promoCodeError, setPromoCodeError] = React.useState('');

    // tip + total
    const [tip, setTip] = React.useState(0);
    const [tipPercentage, setTipPercentage] = React.useState(15);
    const [taxes, setTaxes] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [paymentError, setPaymentError] = React.useState('');
    const [orderError, setOrderError] = React.useState('');

    const changeTipPercentage = (percentage) => {

        let subTotal = parseFloat(cart.subTotal);

        let newTip = (subTotal + parseFloat(shippingCost))*percentage/100;

        setTip(newTip.toFixed(2));
        setTotal(parseFloat(shippingCost)+subTotal+parseFloat(newTip)+parseFloat(taxes));
        setTipPercentage(percentage);
    }

    const changeTipAmount = (event) => {
        let amount = event.target.value
        let subTotal = parseFloat(cart.subTotal);
        let newTip = parseFloat(amount);

        if (newTip > 0){
            if (isNaN(newTip)) {
                newTip = 0;
            }
        
            setTip(newTip);
            setTotal(parseFloat(shippingCost)+subTotal+parseFloat(newTip)+parseFloat(taxes));
            setTipPercentage(null);
        }
    }

    const handleSubmit = async (event) => {
        let clientSecretApiUrl = process.env.REACT_APP_API_URL + '/checkout/external-prepare-payment-single-location';
        
        let body = {
            cart: cart,
            tip: tip,
            shippingCost: shippingCost,
            newCardSelected: true,
            existingCardSelected: 0
        };

        setIsFetching(true);
        const secretResponse = await callApiUrl('POST', clientSecretApiUrl, body);
        
        if (secretResponse.success) {
            
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const paymentResult = await stripe.confirmCardPayment(secretResponse.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: surname + ' ' + name,
                    },
                }
            });

            if (paymentResult.error) {
                // Show error to your customer (e.g., insufficient funds)
                setPaymentError(paymentResult.error.message);
                setIsFetching(false);
                window.scrollTo({top: 0, behavior: 'smooth'});
            } else {
                // The payment has been processed!
                setPaymentError('');
                if (paymentResult.paymentIntent.status === 'succeeded' || paymentResult.paymentIntent.status === 'requires_capture') {

                    let shipping_address = {
                        address: address,
                        appartment: appartment,
                        city: city,
                        state: 'QC',
                        country: 'Canada',
                        postal_code: postalCode,
                        deliveryComment: deliveryComment
                    }

                    let userInfo = {
                        surname: surname,
                        name: name,
                        email: email,
                        phone: phone
                    };

                    // Show a success message to your customer
                    // There's a risk of the customer closing the window before callback
                    // execution. Set up a webhook or plugin to listen for the
                    // payment_intent.succeeded event that handles any business critical
                    // post-payment actions.

                    let newOrderApiUrl = process.env.REACT_APP_API_URL + '/checkout/external-new-order-single-location';

                    let orderBody = {
                        cart: cart,
                        address: shipping_address,
                        userInfo: userInfo,
                        tip: tip,
                        shippingCost: shippingCost,
                        shippingMethod: shippingMethod,
                        paymentId: paymentResult.paymentIntent.id,
                        orderTransferNumber: secretResponse.trs,
                        isDelivery: !isPickup
                    }

                    const orderResponse = await callApiUrl('POST', newOrderApiUrl, orderBody);
                    
                    if (orderResponse.success){
                        navigate("/track/" + orderResponse.invoice_number);
                        dispatch(emptyCart());
                    }else{
                        setOrderError('Une erreur est survenue: ' + orderResponse.message);
                        setIsFetching(false);
                    }
                }
            }
        }else{
            setOrderError('Une erreur est survenue: ' + secretResponse.message);
            setIsFetching(false);
        }
    };

    return (
        <div className="columns">
            <div className={`column is-7 ${cart.count == 0 ? "is-hidden" : null}`}>
                <div className={step != 1 ? "is-hidden" : null} style={{maxWidth: '600px', margin: 'auto'}}>
                    <div className={`panel ${infoError ? "is-danger" : ""}`}>
                        <p className="panel-heading">Vos infos</p>
                        <div className="panel-block">
                            <div className="is-fullwidth">
                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Pr&eacute;nom</label>
                                            <div className="control">
                                                <input 
                                                    className="input" 
                                                    type="text" 
                                                    id="surname" 
                                                    name="surname" 
                                                    placeholder="Prénom"
                                                    onChange={(e) => setSurname(e.target.value)}
                                                    value={surname}
                                                / >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Nom</label>
                                            <div className="control">
                                                <input 
                                                    className="input" 
                                                    type="text" 
                                                    id="name" 
                                                    name="name" 
                                                    placeholder="Nom"
                                                    onChange={(e) => setName(e.target.value)}
                                                    value={name}
                                                / >
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="columns">
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">Courriel</label>
                                            <div className="control">
                                                <input 
                                                    className="input" 
                                                    type="text" 
                                                    id="checkout-email" 
                                                    name="email" 
                                                    placeholder="Courriel"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                / >
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="field">
                                            <label className="label">T&eacute;l&eacute;phone</label>
                                            <div className="control">
                                                <input 
                                                    className="input" 
                                                    type="text" 
                                                    id="phone" 
                                                    name="phone" 
                                                    placeholder="Téléphone"
                                                    onChange={(e) => setPhone(e.target.value)}
                                                    value={phone}
                                                / >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className="columns is-mobile">
                                    <div className="column">
                                        <button id="pickup-button" className={`button is-fullwidth ${isPickup ? "is-active" : ""}`} 
                                            onClick={() => {
                                                dispatch(setPickup(true));
                                                setIsPickup(true)
                                            }} >
                                            Emporter
                                        </button>
                                    </div>
                                    <div className="column">
                                        <button id="delivery-button" className={`button is-fullwidth ${!isPickup ? "is-active" : ""}`} 
                                            onClick={() => {
                                                dispatch(setPickup(false));
                                                setIsPickup(false)
                                            }} >
                                            Livraison
                                        </button>
                                    </div>
                                </div>
                                <div id="shipping-address" className={`${!isPickup ? "appear" : ""}`}>
                                    <div className="columns">
                                        <div className="column is-two-thirds">
                                            <div className="field">
                                                <label className="label">Adresse civique</label>
                                                <div className="control">
                                                    <input 
                                                        className="input" 
                                                        type="text" 
                                                        id="address" 
                                                        name="address" 
                                                        placeholder="Adresse civique"
                                                        onChange={(e) => setAddress(e.target.value)}
                                                        value={address}
                                                    / >
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="field">
                                                <label className="label">Appartement</label>
                                                <div className="control">
                                                    <input 
                                                        className="input" 
                                                        type="text" 
                                                        id="appartment" 
                                                        name="appartment" 
                                                        placeholder="Appartement"
                                                        onChange={(e) => setAppartment(e.target.value)}
                                                        value={appartment}
                                                    / >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div className="field">
                                        <label className="label">Ville</label>
                                        <div className="control">
                                            <input 
                                                className="input" 
                                                type="text" 
                                                id="city" 
                                                name="city" 
                                                placeholder="Ville"
                                                onChange={(e) => setCity(e.target.value)}
                                                value={city}
                                            / >
                                        </div>
                                    </div>
                        
                                    <div className="columns">
                                        <div className="column is-two-thirds">
                                            <div className="field">
                                                <label className="label">Province</label>
                                                <div className="control">
                                                <input className="input" type="text" id="region" name="region" placeholder="Province" disabled value="QC" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="column">
                                            <div className="field">
                                                <label className="label">Code Postal</label>
                                                <div className="control">
                                                    <input 
                                                        className="input" 
                                                        type="text" 
                                                        id="postal_code" 
                                                        name="postal_code" 
                                                        placeholder="Code postal"
                                                        onChange={(e) => setPostalCode(e.target.value)}
                                                        value={postalCode}
                                                    / >
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="field">
                                        <label className="label">Pays</label>
                                        <div className="control">
                                            <input className="input" type="text" id="country" name="country" placeholder="Pays" disabled value="Canada" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <label className="panel-block">
                            <input id="save_profile" name="save_profile" type="checkbox" checked={persistUser} onChange={handlePersistChange}/>
                            &nbsp;Sauvegarder mes informations
                        </label>
                        <div className="panel-block">
                            <div className="prev-next-buttons is-fullwidth">
                                {
                                    infoError ? (
                                        <p className="has-text-danger" style={{borderRadius: 4, paddingTop: '0.5em', paddingBottom: '0.5em', marginBottom: '6px'}}><b>{infoError}</b></p>
                                    ) : null
                                }
                                <button onClick={goToStep2} className={`button is-primary is-fullwidth ${isFetching ? "is-loading" : ""}`}>
                                    Continuer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="checkout-columns" className={step != 2 ? "is-hidden" : null} >
                    {/* <div id="promo-code-div" className="panel">
                        <p className="panel-heading">
                            Vous avez un code promo?
                        </p>
                        <div className="panel-block">
                            <div className="field has-addons is-fullwidth">
                                <div className="control is-expanded">
                                    <input id="promo-code" className="input" type="text" placeholder="Votre code" />
                                </div>
                                <div className="control">
                                    <button id="promo-submit-button" className="button is-primary is-light" onclick="applyPromoCode()">
                                        Appliquer
                                    </button>
                                </div>
                            </div>
                        </div>
                        {
                            promoCodeError ? (
                                <div className="panel-block">
                                    <p className="has-background-danger has-text-white" style={{borderRadius: 4, marginTop: '1em', padding: '0.5em'}}><b>{promoCodeError}</b></p>
                                </div>
                            ) : null
                        }
                        
                    </div> */}
                    <div id="payment-method-div" className={`panel ${paymentError ? "is-danger" : ""}`}>
                        <p className="panel-heading">
                            M&eacute;thode de paiement
                        </p>
                        <div id="payment-div" className="panel-block">
                            <CardElement options={CARD_ELEMENT_OPTIONS} hidePostalCode={true} />
                        </div>
                        
                    </div>
                    <hr/>
                    <div className="panel">
                        <p className="panel-heading">
                            Option de commande
                        </p>
                        <div className="panel-block">
                        {
                            isPickup ? (
                                <p id="pickup-address">
                                    &#8226; Pour emporter<br/>
                                    <small>Pr&eacute;sentez vous au : <b>{location.address}</b> ( Vous serez notifi&eacute; lorsque le commerce aura confirm&eacute; l'heure de ramassage )</small>
                                </p>
                            ) : (
                                <p id="billing-address">
                                    &#8226; Livraison<br/>
                                    <small>{address + ', ' + city + ', ' + postalCode}</small>
                                </p>
                            )
                        }
                        </div>
                        <label className="panel-block" onClick={() => goBack(1)}>
                            <p className="clickable-link">Modifier</p>
                        </label>
                    </div>
                    <hr/>
                    <div className={`panel ${isPickup ? "is-hidden" : ""}`}>
                        <p className="panel-heading">Pourboire au livreur</p>
                        <div className="panel-block">
                            <div className="is-fullwidth">
                                <div className="columns is-mobile is-multiline">
                                    <div className="column is-4-mobile is-2-tablet">
                                        <button className={`button is-fullwidth ${tipPercentage == 15 ? "is-primary" : ""}`} onClick={() => changeTipPercentage(15)}>
                                            <b>15%</b>
                                        </button>
                                    </div>
                                    <div className="column is-4-mobile is-2-tablet">
                                        <button className={`button is-fullwidth ${tipPercentage == 20 ? "is-primary" : ""}`} onClick={() => changeTipPercentage(20)}>
                                            <b>20%</b>
                                        </button>
                                    </div>
                                    <div className="column is-4-mobile is-2-tablet">
                                        <button className={`button is-fullwidth ${tipPercentage == 25 ? "is-primary" : ""}`} onClick={() => changeTipPercentage(25)}>
                                            <b>25%</b>
                                        </button>
                                    </div>
                                    <div className="column">
                                        <div className="field is-fullwidth">
                                            <div className="field has-addons">
                                                <p className="control is-expanded">
                                                    <input className="input" type="number" value={tip} min="0" placeholder="Pourboire (S)" onChange={changeTipAmount} />
                                                </p>
                                                <p className="control">
                                                    <p className="button is-static">
                                                        $
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={isPickup ? "is-hidden" : ""}/>
                    <div id="shipping-choice" className="panel">
                        <p className="panel-heading">
                            Choisissez l'heure
                        </p>
                        {
                            closedMessage ? (
                                <div className="panel-block">
                                    <p className="has-background-warning" style={{borderRadius: 4, padding: '0.5em'}}>{closedMessage}</p>
                                </div>
                            ) : null
                        }
                        <div id="shipping-choice-list" className="list is-hoverable">
                            {
                                !closedMessage ? (
                                    <label className="panel-block" onClick={() => saveShippingMethod('Aussitôt que possible')}>
                                        <p>
                                            <input type="radio" name="shipping-rate" checked={selectedShippingMethod == 'Aussitôt que possible' ? true : false} readOnly />
                                            &nbsp;{asapShippingMethod.description}&nbsp;<i className="icon-rocket"></i> <small>({asapShippingMethod.delivery_label})</small>
                                        </p>
                                    </label>
                                ) : null
                            }
                            <label className="panel-block" onClick={() => saveShippingMethod('Planifiee')}>
                                <input id="planning-slot" type="radio" name="shipping-rate" checked={selectedShippingMethod == 'Planifiee' ? true : false} readOnly />
                                &nbsp;Planifier&nbsp;<i className="icon-clock"></i>
                            </label>
                        </div>
                        <div id="planned-delivery" className={`${selectedShippingMethod == 'Planifiee' ? "appear" : ""}`}>
                            <div className="panel-block columns>">
                                <div className="column">
                                    <div className="select">
                                        <select name="planned-delivery-days" value={selectedDateIndex} onChange={changePlannedDate}>
                                        {
                                            nextDeliveryRates.map((rate, index) =>
                                                <option key={index} value={index}>{rate[0]}</option>
                                            )
                                        }
                                        </select>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="select">
                                        <select name="planned-delivery-schedule" value={selectedHourIndex} onChange={changePlannedHour}>
                                        {
                                            hours.map((suggestion, index) =>
                                                <option key={index} value={index}>{suggestion+'h'}</option>
                                            )
                                        }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div id="confirm-shipping" className="panel">
                        <p className="panel-heading">
                            Commentaires pour la livraison
                        </p>
                        <div className="panel-block">
                            <div className="field is-fullwidth">
                                <div className="control">
                                    <textarea 
                                        id="additionnals_details" 
                                        name="additionnals_details" 
                                        className="textarea" 
                                        placeholder="Facultatif" 
                                        row="3"
                                        onChange={(e) => setDeliveryComment(e.target.value)}
                                        value={deliveryComment}
                                    ></textarea>
                                </div>
                            </div>
                            <p id="shipping-choice-error-message" className="has-text-danger is-hidden"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="column">
                <div id="summary-div" className="panel box-summary sticky" style={{maxWidth: '400px', margin: 'auto'}}>
                    <p className="panel-heading">
                        Sommaire
                    </p>
                    <div id="summary-body">
                        <div className="checkout-products-container">
                        {
                        cart.count == 0 ? (
                            <div>
                                <hr/>
                                <p>Votre panier est vide</p>
                                <hr/>
                            </div>
                        ) : (
                            <div>
                                <Products />
                                {
                                    step == 2 ? (
                                        <label onClick={() => goBack(1, false)}>
                                            <p className="clickable-link">Modifier le panier</p>
                                        </label>
                                    ) : null
                                }
                                <br/>
                                <div id="checkout-total-loading" className="is-hidden">
                                    <p className="has-text-centered"><i className="fontello icon-spin icon-2x animate-spin"></i></p>
                                </div>
                                
                                {/* <div id="promo-subtotal-div" className="is-hidden">
                                    <div className="columns is-mobile" style={{marginBottom: 0}}>
                                        <div className="column is-9">
                                            <div id="promo-tags" className="is-hidden">
                                                <div className="tag is-medium">
                                                    <b id="promo-tag-code" className=""></b>&nbsp;
                                                    <span className="promo-tag-remove" onclick="location.reload()">
                                                        X
                                                    </span>
                                                </div><br/>
                                                <small id="promo-code-text"></small>
                                            </div>
                                        </div>
                                        <div className="column has-text-right">
                                            - <span id="promo-ammount">0</span>$
                                        </div>
                                    </div>
                                    <hr style={{marginTop: 0, marginBottom: '1em'}} />
                                </div> */}
                                <div id="checkout-total">
                                    <div className="columns is-mobile">
                                        <div className="column">
                                            <b>Sous-Total:</b>
                                        </div>
                                        <div className="column has-text-right">
                                            <b>{cart.subTotal}$</b>
                                        </div>
                                    </div>
                                    {
                                        step == 2 ? (
                                            <div>
                                            {
                                            !isPickup ? (
                                                <div>
                                                    <div className="columns is-mobile">
                                                        <div className="column">
                                                            <b>Livraison:</b>
                                                        </div>
                                                        <div className="column has-text-right">
                                                            <b>{parseFloat(shippingCost).toFixed(2)}$</b>
                                                        </div>
                                                    </div>
                                                    <div className="columns is-mobile">
                                                        <div className="column">
                                                            <b>Pourboire:</b>
                                                        </div>
                                                        <div className="column has-text-right">
                                                            <b>{parseFloat(tip).toFixed(2)}$</b>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) : null
                                            }
                                                <div className="columns is-mobile">
                                                    <div className="column">
                                                        <b>Taxes:</b>
                                                    </div>
                                                    <div className="column has-text-right">
                                                        <b>{parseFloat(taxes).toFixed(2)}$</b>
                                                    </div>
                                                </div>
                                                <div className="columns is-mobile">
                                                    <div className="column">
                                                        <b>Total:</b>
                                                    </div>
                                                    <div className="column has-text-right">
                                                        <b>{total.toFixed(2)}$</b>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null
                                    }
                                </div>
                            </div>
                        )
                        }
                        </div>
                    </div>
                    {
                        step == 2 ? (
                            <div id="payment-buttons-div">
                                <div id="payment-buttons" className="prev-next-buttons">
                                    {
                                        paymentError ? (
                                            <p className="has-text-danger" style={{borderRadius: 4, paddingTop: '0.5em', paddingBottom: '0.5em', marginBottom: '6px'}}><b>{paymentError}</b></p>
                                        ) : null
                                    }
                                    {
                                        orderError ? (
                                            <p className="notification is-danger" style={{borderRadius: 4, paddingTop: '0.5em', paddingBottom: '0.5em', marginBottom: '6px'}}><b>{orderError}</b></p>
                                        ) : null
                                    }
                                    <button onClick={handleSubmit} className={`button is-primary is-fullwidth ${isFetching ? "is-loading" : ""}`} disabled={orderError ? true : false}>
                                        <b>Payer</b>&nbsp;({total.toFixed(2)}$)
                                    </button>
                                </div>
                                <br/>
                                <p className="payment-text has-text-centered">
                                    <i className="fontello icon-lock"></i> 
                                    Paiement s&eacute;curis&eacute; par <img src="/imgs/stripe-payment-method.png" />
                                </p>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
}

export default Checkout;
