import { useEffect } from 'react'
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
    getZipCode
  } from "use-places-autocomplete";
  import useOnclickOutside from "react-cool-onclickoutside";
  
const PlacesAutocomplete = ({ setSelected, parentValue }) => {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        types: ['address'],
        componentRestrictions: { country: "ca" },
      },
      debounce: 500,
    });
    const ref = useOnclickOutside(() => {
      // When user clicks outside of the component, we can dismiss
      // the searched suggestions by calling this method
      //clearSuggestions();
    });
  
    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
    };
  
    const handleSelect =
      ({ description }) =>
      () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();
  
        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          const { lat, lng } = getLatLng(results[0]);
          const zipCode = getZipCode(results[0], false);
          setSelected(description, { lat, lng }, zipCode);
        });
    };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        return (
          <a key={place_id} onClick={handleSelect(suggestion)} className="dropdown-item p-4" style={{borderBottom: '1px solid #ddd', whiteSpace: 'normal'}}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </a>
        );
    });

    useEffect(() => { 
      if (parentValue != value){
        setValue(parentValue, false);
        clearSuggestions();
      }
    }, [parentValue]);
  
    return (
      <div ref={ref}>
        <div className="dropdown is-active is-fullwidth" style={{flexDirection: 'column'}}>
          <div className="dropdown-trigger is-fullwidth">
            <p className="control">
              <input
                id="autocomplete"
                className="input control"
                value={value}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Entrez l'adresse"
              />
          </p>
        </div>
          {status === "OK" ? ( 
            <div className="dropdown-menu is-fullwidth" id="dropdown-menu" role="menu" style={{position: 'relative'}}>
              <div className="dropdown-content">
                {renderSuggestions()}
              </div>
            </div>
            ) : null
          }
        </div>
      </div>
    );
  };

  export default PlacesAutocomplete