import React from 'react';

function Map({ center, zoom, title }) {
    const ref = React.useRef();
  
    React.useEffect(() => {
        const map = new window.google.maps.Map(ref.current, {
            center,
            zoom,
            disableDefaultUI: true,
        });

        new window.google.maps.Marker({
            position: center,
            map,
            title: title,
        });
    });
    
    return (
        <>
          <div ref={ref} id="map" />
        </>
      );
}

export default Map;
