import React from 'react';
import { useSelector } from 'react-redux'
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import Map from '../components/Map'

function About() {
    
    const location = useSelector(state => state.location);
    const schedule = useSelector(state => state.schedule);
    const isLoadingLocation = useSelector(state => state.isLoadingLocation);
    const center = location.latitude != null ? { lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) } : { lat: 45.536523, lng: -73.5075797 };
    const zoom = 13;

    const render = (status) => {
        if (status === Status.LOADING || isLoadingLocation) return <p className="placeholder-item" style={{marginTop: '1em', width: '100%', height: '300px'}}></p>;
        if (status === Status.FAILURE) return <p>Erreur lors du chargement de la carte</p>;
        return null;
    };

    return (
        <div>
            <section className="map-section-wrapper">
                <Wrapper apiKey={process.env.REACT_APP_GOOGLE_MAP_API} render={render}>
                    <Map center={center} zoom={zoom} title={location.name} />
                </Wrapper>
            </section>
            <h2 className="is-size-4"><b>{location.address}</b></h2>
            <p><a href={`https://maps.google.com/?q=${location.address}`} className="clickable-link" target="_blank">Obtenir l'itinéraire</a></p>
            <hr/>
            <div className="columns">
                <div className="column">
                    <div className="box">
                        <p className="label is-size-5">
                            Horaire
                        </p>
                        <hr className="is-hidden-mobile" />
                        <aside className="menu">
                            {
                                isLoadingLocation ? (
                                    <div>
                                        <p className="placeholder-item" style={{width: '85%'}}>
        
                                        </p>
                                        <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
        
                                        </p>
                                        <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
        
                                        </p>
                                        <p className="placeholder-item" style={{marginTop: '1em', width: '85%'}}>
        
                                        </p>
                                    </div>
                                ) : (
                                    <ul className="menu-list">
                                        {schedule.map((day, index) =>
                                            <div key={index} className="columns is-mobile" style={{maxWidth: 300, marginBottom: 0}}>
                                                <div className="column">
                                                    <p style={day.day_is_today ? {fontWeight: 'bold'} : null}>{day.label}</p>
                                                </div>
                                                <div className="column">
                                                    <p style={day.day_is_today ? {fontWeight: 'bold'} : null}>{day.day_closed ? 'Fermé' : day.start + ' - ' + day.end}</p>
                                                </div>
                                            </div>
                                        )}
                                    </ul>
                                )
                            }
                        </aside>
                    </div>
                </div>
                <div className="column">
                    <div className="box" style={{marginLeft: 'auto'}}>
                        <p className="label is-size-5">
                            Téléphone
                        </p>
                        <hr className="is-hidden-mobile" />
                        <aside className="menu">
                            <p><a href={`tel:${location.phone}`} className="clickable-link">{location.phone}</a></p>
                        </aside>
                    </div>
                    <div className="box" style={{marginLeft: 'auto'}}>
                        <p className="label is-size-5">
                        Modes de paiement
                        </p>
                        <hr className="is-hidden-mobile" />
                        <aside className="menu">
                            <p>Pour emporter: carte de crédit</p>
                            <p>Livraison: carte de crédit</p>
                        </aside>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
